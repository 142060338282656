import React, { useState, useEffect, useContext, useRef } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, IconButton, Typography, Button, Modal, Stack, TextField, Card, Avatar, Popover, FormGroup, FormControlLabel, createTheme, Snackbar, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';

import LimitedMultilineOutlinedBordered from '../Textfield/limited_multiline_outlined_bordered';
import MoreButton from '../Buttons/more';
import { MyContext } from '../../routes/router';
import { OpenAiChatGpt_Get_Midjourney_Text, OpenAiChatGpt_Get_SubTitle_Point } from '../API/openai';
import BannerCarousel from '../Carousel/BannerCarousel';
import { UseQueryResult } from 'react-query';
import { useGetRequests } from '../API/requests';
import { simple } from '../../interfaces/request';
import { usePutProject } from '../API/projects';

export function HelpIcon(props: {help:string}){
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.TouchEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <Stack position={'relative'}>
            <IconButton onTouchStart={handleClick}>
                <HelpOutlineIcon sx={{ color: "#383E86", fontSize: 18 }} />
            </IconButton>
            <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onTouchEnd={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            >
    
            <Typography 
            sx={{
                fontSize: 14, 
                color:'inherit', 
                backgroundColor: '#f5f5f9', 
                border: '1px solid #dadde9',
                padding: '4px',
                maxWidth: '220px'
            }}>
                {props.help}
            </Typography>
            </Popover>
        </Stack>
    )
}
  
export const Header = (title: string, required:boolean | null, help:string, isMobile?:boolean, ratio?: number) => {
    return(
        <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"flex-start"} spacing={isMobile ? 1 : 2}>
        
        <Typography  component="div" sx={{letterSpacing: '2px'}}>
            <Box textAlign={"center"} sx={{ borderRadius:13 * (ratio ?? 1) + "px", width:(isMobile ? 50 : 55) * (ratio ?? 1)+"px", display:required === null ? "none" : "block", backgroundColor:required ? "#383E86" : "#707070", color: "white", fontSize:(isMobile ? 13 : 14) * (ratio ?? 1), fontWeight: 'bold', }}>
                {required === true ? "必須" : "任意"}
            </Box>
        </Typography>

        <Typography  component="div" sx={{letterSpacing: '2px'}}>
            <Box textAlign={"center"} sx={{ borderRadius:12 * (ratio ?? 1) + "px", width:"100%", color: "#5E5E5E", fontSize:(isMobile ? 17 : 18) * (ratio ?? 1), fontWeight: 'bold', }}>
                {title}
            </Box>
        </Typography>
        <HelpIcon help={help}/>
        </Stack>
    )
}

const styleSP = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    height: 600,
    backgroundColor: '#F6F8FA',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5,
};

const stylePC = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 680,
    backgroundColor: '#F6F8FA',
    justifyContent: "center",
    alignItems: "center",
    borderRadius:5,
};

// Propsの型を定義
interface Props {
    handleClose: () => void;
    open: boolean;
    isMobile?: boolean;
    handleOpenRegister?: () => void;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    ratio?: number;
}

const PopupIdeaPostMini: React.FC<Props> = ({ ratio, handleClose, open, setOpen, isMobile, }) => {
    const [checked, setChecked] = useState(true);
    const {user, openLoginPopup, socket } = useContext(MyContext);
    const [title, setTitle] = React.useState<string>("");
    const [categories, setCategories] = React.useState<(string | null)[]>([]);
    const [description, setDescription] = React.useState<string>("");
    const [open_snackBar_subtitles, setOpenSnackBarSubtitles] = React.useState<boolean>(false);
    const [open_snackBar_midjourney, setOpenSnackBarMidjourney] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [selectedRequestID, setSelectedRequestID] = React.useState<string|null>(null);
    const {data: requests, } : UseQueryResult<simple[] | null, Error> & { queryKey: string } = useGetRequests("requests_simple", "simple", null);

    const putMutation = usePutProject();

    // コンポーネントの初回レンダリング時にsessionStorageから値を読み込む
    useEffect(() => {
        const storedValue = localStorage.getItem('editData');
        if (storedValue) {
            setTitle(JSON.parse(storedValue).title);
            setDescription(JSON.parse(storedValue).description);
        }
    }, []);


    useEffect(() => {
        const editData = { 
            title: title, 
            description: description, 
        }
        localStorage.setItem('editData', JSON.stringify(editData)); 
    }, [title, description, selectedRequestID]);

    const [title_error, setTitleError] = React.useState<boolean>(false);
    const [description_error, setDescriptionError] = React.useState<boolean>(false);

    // 投稿する前の各項目のエラーチェック
    const checkRequiredValue = () => {
        let flag = false
        if(title.trim() === ""){setTitleError(true); flag=true}
        if(description.trim() === "") {setDescriptionError(true); flag=true}
        return flag;
    }

    useEffect(() => {
        if(title.trim() !== "") setTitleError(false);
    }, [title]);
    useEffect(() => {
        if(description.trim() !== "") setDescriptionError(false);
    }, [description]);

    const styles = isMobile === true ? styleSP : stylePC;

  return (
    <>
    <Modal open={open} onClose={handleClose} sx={{border:"none"}}>
        <Fade in={open}>
            <div style={{...styles, position: 'relative', backgroundColor:"transparent" }}>

                {loading && 
                    <div
                        style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        zIndex:1000,
                        transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <CircularProgress size={60} sx={{color:"white"}}/>
                    </div>
                }
                <Stack pt={2} sx={{...styles, filter: loading ? 'brightness(50%)' : 'none',}}>
                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <IconButton onClick={()=>{handleClose();}}>
                            <CloseIcon  sx={{fontSize:24}} />
                        </IconButton>
                    </div>
                    <Stack p={1.5} justifyContent={"center"} alignItems={"center"} sx={{backgroundColor:'white', borderRadius: "7px",}} width={'90%'} height={"90%"} py={1}>
                        {/* お題を選ぶ */}
                        <Stack mt={0} spacing={0.5} justifyContent={"flex-start"} sx={{width: "100%"}}>
                            {Header("お題を選ぶ", false, "企業からのIDEA募集のお題に答えることで、IDEA実現率が大きく上がります！", isMobile, ratio)}
                            <div onMouseEnter={() => {}} onMouseLeave={() => {}}>
                                <BannerCarousel isSelectedBorder setSelectedID={setSelectedRequestID} IDs={[null, ...(requests?.map((request:simple) => request.request_id)) ?? []]} playOnInit={false} aspectRatio='10/3.5' borderRadius={15} isMobile={isMobile} delay={0} img_srcs={[process.env.REACT_APP_PUBLIC_URL+"/images/odainull.png", ...(requests?.map((request: simple) => process.env.REACT_APP_PUBLIC_URL+"/images/"+request.image_name) ?? [])]}/>
                            </div>
                        </Stack>
                        
                        {/* IDEAタイトル */}
                        <Stack mt={0} spacing={0.5} justifyContent={"flex-start"} sx={{width: "100%"}}>
                            {Header("IDEAタイトル", true, "わかりやすく、オリジナリティ溢れる\n題名を30字以内で考えましょう!\n", isMobile, ratio)}
                            <div onMouseEnter={() => {}} onMouseLeave={() => {}}>
                                <LimitedMultilineOutlinedBordered width='100%' padding='8px 12px' fontSize={(isMobile ? 14 : 16)*(ratio ?? 1) + 'px'} error={title_error} placeholder='IDEAのタイトルを記入してください' value={title} setValue={setTitle} max={30} maxdisplay={true} />
                            </div>
                        </Stack>
                        
                        {/* IDEA説明(詳細) */}
                        <Stack mt={0} spacing={0.5} justifyContent={"flex-start"} alignItems={"center"} sx={{width: "100%"}}>
                            {Header("詳細", true, "あなたのIDEAの素晴らしさを300字以内で説明しましょう!", isMobile, ratio)}
                            <div style={{width:"100%"}} onMouseEnter={() => {}} onMouseLeave={() => {}}>
                                <LimitedMultilineOutlinedBordered width='100%' rows={4} padding='8px 12px' fontSize={(isMobile ? 14 : 16) * (ratio ?? 1)+'px'} maxdisplay error={description_error}  placeholder='あなたのIDEAの素晴らしさ・ストーリーを200字以内で説明しましょう!' maxRows={3} value={description} setValue={setDescription} max={200}/>
                            </div>
                        </Stack>

                        {/* IDEA詳細ページボタン */}
                        <div style={{width: "100%", marginTop:isMobile ? 10 : 40, marginBottom: 10}}>
                            <MoreButton borderRadius='10px' width='100%' height={(isMobile ? 35 : 45) * (ratio ?? 1)+'px'} text='あなたの「IDEA詳細ページ」を作成' fontSize={(isMobile ? 13 : 14) * (ratio ?? 1) + "px"} 
                                onClick={() => {
                                    if(checkRequiredValue() === false){
                                        if(user.user_id !== undefined){
                                            // サブタイトル と アピールポイント をChatGPTにより取得
                                            setLoading(true)
                                            setOpenSnackBarSubtitles(true)
                                            OpenAiChatGpt_Get_SubTitle_Point(title, description, async (res:any) => {
                                                const result = res.data.result;
                                                // 仮保存状態のIDEAを生成(画像等 不必要な情報は空)
                                                await putMutation.mutate({project_id:null, newValues:{
                                                    "request_id": selectedRequestID ?? "null",
                                                    "title": title,
                                                    "subtitle": result.subTitle,
                                                    "description": description,
                                                    "point1": result.point1,
                                                    "point2": result.point2,
                                                    "point3": result.point3,
                                                    "phase": "idea_draft",
                                                }}, {onSuccess:async (_res:any) => {
                                                    setOpenSnackBarMidjourney(true)
                                                    await OpenAiChatGpt_Get_Midjourney_Text(title, description, async (res: any) => {
                                                        // IDEA外観の説明が取得できたら、それをローカルストレージに格納して、AI画像生成の許可を出してから IDEA詳細ページに遷移する。
                                                        localStorage.setItem("midjourney_text", res.data.result.midjourney_text);
                                                        // 遅延させて処理を安定させる
                                                        setTimeout(() => {window.location.href = "project/idea?projectID=" + _res.project_id + "&edit=true&midjourney=true";}, 500);
                                                    }, () => {console.log("failed")});
                                                }})
                                            }, () => {console.log("failed")})
                                        }else{
                                            openLoginPopup()
                                        }
                                    }
                                    // アイデアを仮保存状態(phase=-1)で保存して、編集用URLにリダイレクトする
                                }}
                            />
                        </div>
                    </Stack>
                </Stack>

            </div>
        </Fade>
    </Modal>

    <Snackbar
        open={open_snackBar_subtitles}
        autoHideDuration={10000}
        onClose={()=>{setOpenSnackBarSubtitles(false)}}
        sx={{
            '& .MuiSnackbarContent-root': {
                fontSize: isMobile ? '12px' : "14px", // ここでフォントサイズを指定
                width:"400px",
            },
            }}
        message={ 
            <span style={{ width:"400px", display: 'flex', alignItems: 'center' }}>
                <Typography fontSize={(isMobile ? 13 : 15) * (ratio ?? 1)} sx={{marginLeft:"5px", marginRight: "10px"}}>
                    {open_snackBar_midjourney === false ? "サブタイトルとアピールポイントを自動生成中" : "サブタイトルとアピールポイントを自動生成完了"}
                </Typography>
                {open_snackBar_midjourney === false && <CircularProgress size={isMobile ? 15 : 18} sx={{ marginRight: isMobile ? '8px' : "14px", color: 'inherit' }} />}
            </span>
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // ここで位置を指定
      />

    <Snackbar
        open={open_snackBar_midjourney}
        autoHideDuration={10000}
        onClose={()=>{setOpenSnackBarMidjourney(false)}}
        sx={{
            '& .MuiSnackbarContent-root': {
                fontSize: isMobile ? '12px' : "14px", // ここでフォントサイズを指定
                marginBottom: isMobile ? '50px' : "60px",
                width:"400px",
            },
            }}
        message={
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography fontSize={(isMobile ? 13 : 15) * (ratio ?? 1)} sx={{marginLeft:"5px", marginRight: "10px"}}>
                    {"IDEA画像を自動生成中"}
                </Typography>
                <CircularProgress size={isMobile ? 15 : 18} sx={{ marginRight: '8px', color: 'inherit' }} />
            </span>}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // ここで位置を指定
      />
    </>
  );
};

export default PopupIdeaPostMini;