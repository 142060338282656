import React, { useState, useEffect } from 'react';
import axios from 'axios';

// メールアドレスに認証メールを送信する
export const Authenticate = (mail:string, code:string, success_func: () => void) => {
    axios.post(process.env.REACT_APP_API_URL+'/register/authenticate', {mail: mail, code:code})
        .then(res => {
            if(res.status === 200) success_func()
        }).catch((err) => {
            console.log(err);
        });
}

// メールアドレスに認証メールを送信する
export const SetNewPasswordByMail = (mail:string, success_func: () => void, failed_func: () => void) => {
    axios.post(process.env.REACT_APP_API_URL+'/setnewpassword', {mail: mail})
        .then(res => {
            if(res.data.result === "success") success_func()
            else if(res.data.result === "failed") failed_func()
        }).catch((err) => {
            console.log(err);
        });
}

// メールアドレスに認証メールを送信する
export const AuthenticateCode = (code:string, success_func: () => void, failed_func: () => void) => {
    axios.post(process.env.REACT_APP_API_URL+'/setnewpassword/authenticatebycode', {code: code})
        .then(res => {
            if(res.data.result === "success") success_func()
            else if(res.data.result === "failed") failed_func()
        }).catch((err) => {
            console.log(err);
        });
}
