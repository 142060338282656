import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { InputAdornment, Grid, IconButton, Typography, Button, Stack, TextField, Card, Avatar, styled } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';

type TextAlign = "left" | "right" | "center" | "justify";


interface TagsProps {
    value?: string | number | null,
    placeholder: string,
    setValueNum?: Dispatch<SetStateAction<number | null>> | ((value:number | null) => void);
    setValue?: Dispatch<SetStateAction<string>> | ((value:string) => void);
    max?: number,
    rows?: number;
    maxdisplay?: boolean,
    maxFontSize?: number,
    error?: boolean,
    border?: string,
    padding?: string,
    backgroundColor?: string;
    hovered_backgroundColor?: string;
    letterSpacing?: number;
    fontSize?: number | string;
    disabled?: boolean;
    width?: string;
    height?: number;
    maxRows?:number;
    textAlign?: TextAlign;
    lineHeight?: number;
    borderBottom?:string;
    bold?: boolean;
    vertical?: boolean;
    color?: string;
    italic?: boolean;
    borderRadius?: number;
    readOnly?: boolean;
    errorCheck?: (text:string)=>boolean
    focus?: boolean
    setFocus?: Dispatch<SetStateAction<boolean>>
    focusTimeout?: number
    inputRef?: React.RefObject<HTMLInputElement>;
    type?: string;
    unit?: string;
    customInputProps?: Partial<StandardInputProps | OutlinedInputProps>;
    onKeyEnterAndMeta?: () => void;
}

const LimitedMultilineOutlinedBordered: React.FC<TagsProps> = ({ value, setValue, setValueNum, lineHeight, letterSpacing, rows, max, maxFontSize, maxdisplay, placeholder, error, border, padding, backgroundColor, fontSize, disabled, errorCheck, width, maxRows, textAlign, borderBottom, bold, italic, borderRadius, hovered_backgroundColor, color, readOnly, vertical, focus, setFocus, focusTimeout, inputRef, type, unit, customInputProps, onKeyEnterAndMeta }) => {
    
    const [valid, setValid] = useState<boolean>(true)
    const [focused, setFocused] = useState<boolean>(true)
    const [hovered, setIsHovered] = useState<boolean>(false)
    useEffect(() => {
        if(value !== null && typeof(value) === "string" && value?.trim() != "" && errorCheck && errorCheck(value ?? "")){
            setValid(false)
        }else{
            setValid(true)
        }
    }, [value]);

    useEffect(() => {
        if(focus === true){
            handleButtonClick()
            if(setFocus)setFocus(false)
        }
    }, [focus]);

    const handleButtonClick = () => {
        setTimeout(() => {
            if (inputRef && inputRef.current) inputRef.current.focus();
        }, focusTimeout ?? 500);
    };
    
    return(
        <Stack direction={"column"} sx={{width:width, height: vertical ? "100%" : undefined}} >
            <Stack width={"100%"} sx={{height:"100%"}} alignItems={"center"} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <TextField type={type ?? undefined} inputRef={inputRef} autoFocus multiline={maxRows ? maxRows > 1 : false} maxRows={maxRows ?? 1} variant="outlined" rows={rows ?? undefined} disabled={disabled ? disabled : false} sx={{ width:"100%",
                border: error ? "1px solid red" : border ?? undefined,
                height:"100%",
                borderRadius: borderRadius ?? '10px',
                borderBottom:border ? undefined : borderBottom ?? undefined, 
                backgroundColor: hovered ? (hovered_backgroundColor ?? "#F6F6F6") : backgroundColor ?? "#F6F6F6",
                '& .MuiOutlinedInput-root': {
                    paddingRight: "0px",
                    '& fieldset': {
                        border:"none",
                        borderRadius: borderRadius ?? '10px',
                    },
                },
                '& .MuiInputBase-root': {
                    writingMode: vertical ? 'vertical-rl' : 'horizontal-tb',
                    textOrientation: vertical ? 'upright' : 'mixed',
                  },
                '& .MuiInputBase-input': {
                    writingMode: vertical ? 'vertical-rl' : 'horizontal-tb',
                    textOrientation: vertical ? 'upright' : 'mixed',
                    height:vertical ? "100%" : undefined,
                },

                "& .MuiInputBase-multiline ":{
                    display: 'flex',
                    alignItems: 'center', // Center align items vertically
                    padding: "0px 0px", // マルチラインの場合、余白をリセットする
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {border: "none", },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': { border: "none",},
                }}
                InputProps={{
                    endAdornment: unit ? <InputAdornment position="end" sx={{paddingRight: "20px", fontSize:fontSize ?? 16}}>{unit}</InputAdornment> : undefined,
                    ...customInputProps, // Spread custom InputProps here
                }}
                inputProps={{
                    
                    readOnly: readOnly ?? false,
                    style: { 
                    fontWeight: bold ? "bold" : undefined,
                    textAlign:textAlign ?? "left", 
                    color: color ?? undefined,
                    fontSize: fontSize ? fontSize : 16, 
                    fontStyle: italic ? "italic" : undefined,
                    letterSpacing: letterSpacing ?? 1,
                    padding: padding ? padding :14,
                    lineHeight: lineHeight ?? (fontSize ? parseInt(fontSize as string, 10)+10+"px" : 20 + 'px') // Add or update this line
                    },
                }}
                onKeyDown={(e)=>{
                    if( e.key === "Enter" && e.metaKey){
                     if(onKeyEnterAndMeta) onKeyEnterAndMeta()
                    }
                }}
                
                value={value}
                placeholder={placeholder}
                onChange={(e) => {
                    if(max && e.target.value.length > max){
                        if(setValue) setValue(e.target.value.substring(0, max))
                        if(setValueNum) setValueNum(Number(e.target.value))
                    }else{
                        if(setValue) setValue(e.target.value)
                        if(setValueNum) setValueNum(Number(e.target.value))
                    }
                    if(errorCheck && errorCheck(e.target.value)){
                        setValid(false)
                    }else{
                        setValid(true)
                    }
                    if(e.target.value == ""){
                        setValid(true)
                    }
                }}
            />
            {max && maxdisplay &&  typeof(value) === "string" &&
                <Stack width={width} direction={"row"} justifyContent={"flex-end"}>
                    <Typography sx={{fontSize: maxFontSize ?? (fontSize ? Number(fontSize)-3 : fontSize), color: value?.length ? value?.length >= max ? "#e60000" : "grey" : "grey"}}>
                        {value?.length ?? 0} / {max}
                    </Typography>
                </Stack>
            }
            </Stack>
        </Stack>
    )
    
}

export default LimitedMultilineOutlinedBordered;
