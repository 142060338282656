import React, { useState, useContext } from 'react';
import { Stack, Typography, TextField, Button, Drawer, Divider, IconButton} from '@mui/material';
import reidea_logo from "./../img/RE-IDEA.png"
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ProfilePopover from './Popover/profile';
import { useSearchParams } from "react-router-dom";
import { MyContext } from '../routes/router';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLogout, useGetUsers } from './API/users';
import { useRatio } from './useRatio';
import { UseQueryResult } from 'react-query';
import { simple } from '../interfaces/user';

const theme = createTheme({
  palette: {
      secondary: {
        main: '#383E86',
      },
      text: {
        secondary: '#AAAAAA', // タブが選択されていない状態のテキスト色
      },
  },
});


// APIからのデータ
interface Props {
}

const HeaderSP: React.FC<Props> = (props) => {

  const { user, openRegisterPopup, openLoginPopup, openBetaLimitPopup } = React.useContext(MyContext);

  const [searchValue, setSearchValue] = useState(""); // 入力された値を保持する状態
  const [anchorEL, setAnchorEL] = React.useState<HTMLElement | null>(null);
  const [menu, setMenu] = React.useState<HTMLElement | null>(null);

  const [searchParams] = useSearchParams();
  // registerIDという名前のuserID
  const registerID = searchParams.get("registerID");
  // APIから情報を取得
  const {data: provisional_user } : UseQueryResult<simple | null, Error> & { queryKey: string } = useGetUsers("users_simple", "simple", registerID ?? "none", );
  
  const ratio = useRatio()

  const mutateLogout = useLogout()

  React.useEffect(() => {
    // 仮登録をした時のユーザー情報が見つからないか、SNSログインがそもそも失敗しているか
    if((registerID !== null && provisional_user === null) || registerID === ""){
      openRegisterPopup();
      // 仮登録しているユーザーが存在するならば登録フォームを再展開
    }else if(provisional_user?.provisional === true){
      openRegisterPopup()
    }
  }, [registerID, provisional_user]);


  return(
    <ThemeProvider theme={theme}>
      <Stack alignItems={"center"} justifyContent={"space-between"} direction={"row"} minWidth={320} sx={{height:"60px",top:0, backgroundColor:"white", position:"sticky", zIndex:10}} width={"100%"}>
        <Stack borderBottom={"1px solid rgba(220, 220, 220, 0.6)"} justifyContent={"space-around"} pl={'15px'} pr={1} sx={{height:"100%", width:"100%"}}>
          <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
            <Stack direction="row" spacing={3} alignItems={"center"} sx={{cursor:"pointer"}} onClick={() => {window.location.href="/"}}>
              <img src={reidea_logo} alt="" style={{height: "35px"}}/>
            </Stack>

            <Stack direction={"row"} spacing={5} height={"100%"}>
              <Stack direction="row" alignItems={"center"} spacing={3} height={"100%"}>

                <Stack onClick={(e: React.MouseEvent<HTMLElement>)=>{setMenu(e.currentTarget)}} ml={0}>
                  <MenuIcon sx={{ cursor:"pointer", color:"#383E86", fontSize:27}}/>
                </Stack>

                <Drawer
                  anchor={"right"}
                  open={Boolean(menu)}
                  sx={{zIndex:11}}
                  onClose={()=>{setMenu(null)}}
                  PaperProps={{
                    style: {
                      backgroundColor: '#383E86',
                      width:60-(ratio-1.5)*7+"%"
                    },
                  }}
                  >
                  <Stack >
                    {/* バツボタン */}
                    <Stack display={"flex"} alignItems={"flex-end"} height={"60px"} sx={{pr:1}} mr={'5px'} justifyContent={'center'}>
                      <IconButton sx={{p:0}} onClick={()=>{setMenu(null)}}>
                        <CloseIcon sx={{fontSize:"40px", color:'white'}}/> 
                      </IconButton>
                    </Stack>

                    {/*  */}
                    <Stack direction={"row"} p={1} mb={2} spacing={2} width={"100%"} alignItems={"center"}>
                      <SearchIcon sx={{fontSize:26, color:"white"}} />
                      <TextField variant='outlined' value={searchValue} onChange={(e) => {setSearchValue(e.target.value)}}
                        placeholder='キーワードを入力してください'
                        onKeyPress={(e) => {
                          if(e.key === 'Enter') {
                            window.location.href="/search?mode=idea&q="+searchValue;
                          }
                        }}
                        sx={{
                          width:"80%",
                          '& .MuiInputBase-root.MuiOutlinedInput-root': {
                          padding: 0,
                          backgroundColor:"white",
                          height:"35px",
                        },}}
                        InputProps={{
                          style:{fontSize:"12px"},
                        }}
                      />
                    </Stack>

                    <Divider sx={{backgroundColor:"white", height:"1px"}}/>
                    <Stack pl={3} alignItems={"center"} direction={"row"}>
                      {/* <QuestionMarkIcon sx={{ color:"white", fontSize:24, marginRight:"12px"}}/> */}
                      <Typography display={"flex"} alignItems={"center"} height={"40px"} color={"white"} onClick={()=>{window.location.href="https://www.re-idea.jp"}} sx={{fontSize: "14px", fontWeight:"bold"}}>RE-IDEAとは</Typography>
                    </Stack>

                    {/* ゲストユーザー状態 */}
                    {user?.user_id === undefined &&
                      <>
                        <Divider sx={{backgroundColor:"white", height:"1px"}}/>
                        <Stack pl={3} alignItems={"center"} direction={"row"}>
                          {/* <TipsAndUpdatesIcon sx={{ color:"white", fontSize:24, marginRight:"12px"}}/> */}
                          <Typography display={"flex"} alignItems={"center"} height={"40px"} color={"white"} onClick={()=>{window.location.href="/search?mode=idea"}} sx={{fontSize: "14px", fontWeight:"bold"}}>IDEA一覧</Typography>
                        </Stack>

                        <Divider sx={{backgroundColor:"white", height:"1px"}}/>

                        <Stack pl={3} alignItems={"center"} direction={"row"}>
                          {/* <ShoppingCartIcon sx={{ color:"white", fontSize:24, marginRight:"12px"}}/> */}
                          <Typography display={"flex"} alignItems={"center"} height={"40px"} color={"white"} sx={{fontSize: "14px", fontWeight:"bold"}} onClick={()=>{openBetaLimitPopup()}} >商品一覧</Typography>
                        </Stack>
                        <Divider sx={{backgroundColor:"white", height:"1px"}}/>
                      </>
                    }

                    
                    {/* ログイン状態 */}
                    {user?.user_id !== undefined &&
                      <>

                        <Divider sx={{backgroundColor:"white", height:"1px"}}/>
                        <Stack pl={3} alignItems={"center"} direction={"row"}  onClick={()=>{window.location.href="/mypage?mode=posted_idea"}}>
                          <Typography display={"flex"} alignItems={"center"} height={"40px"} color={"white"} sx={{fontSize: "14px", fontWeight:"bold"}}>あなたのIDEA</Typography>
                        </Stack>

                        <Divider sx={{backgroundColor:"white", height:"1px"}}/>
                        <Stack pl={3} alignItems={"center"} direction={"row"} onClick={()=>{window.location.href="/mypage?mode=favorite"}}>
                          <Typography display={"flex"} alignItems={"center"} height={"40px"} color={"white"} sx={{fontSize: "14px", fontWeight:"bold"}} >お気に入り</Typography>
                        </Stack>
                        <Divider sx={{backgroundColor:"white", height:"1px"}}/>

                        <Stack pl={3} alignItems={"center"} direction={"row"} onClick={()=>{openBetaLimitPopup()}}>
                          <Typography display={"flex"} alignItems={"center"} height={"40px"} color={"white"} sx={{fontSize: "14px", fontWeight:"bold"}} >購入履歴</Typography>
                        </Stack>
                        <Divider sx={{backgroundColor:"white", height:"1px"}}/>

                        <Stack pl={3} alignItems={"center"} direction={"row"} onClick={()=>{openBetaLimitPopup()}}>
                            <Typography display={"flex"} alignItems={"center"} height={"40px"} color={"white"} sx={{fontSize: "14px", fontWeight:"bold"}} >
                              アカウント設定
                            </Typography>
                        </Stack>
                        <Divider sx={{backgroundColor:"white", height:"1px"}} />
                      </>
                    }
                    {user?.user_id !== undefined &&
                      <>
                        <Stack pl={3} alignItems={"center"} direction={"row"}>
                          {/* <LogoutIcon sx={{ color:"white", fontSize:24, marginRight:"12px"}}/> */}
                          <Typography display={"flex"} alignItems={"center"} height={"40px"} color={"white"} sx={{fontSize: "14px", fontWeight:"bold"}} onClick={()=>{mutateLogout.mutate()}}>
                            ログアウト
                          </Typography>
                        </Stack>
                        <Divider sx={{backgroundColor:"white", height:"1px"}} />
                      </>
                    }

                    <Stack direction="row" sx={{width:"100%", p:2}} justifyContent={"center"} gap={2}>
                      {user?.user_id === undefined &&
                        <Button 
                        variant='contained' 
                        onClick={() => {openLoginPopup()}} 
                        sx={{width:"95px", fontSize:10, fontWeight:"bold", color: "#383E86", borderRadius:"24px", backgroundColor:"white"}}>
                          ログイン
                        </Button>
                      }
                      {user?.user_id === undefined &&
                          <Button 
                          variant='outlined'
                          onClick={()=>{openRegisterPopup()}}
                          sx={{width:"95px", fontSize:10, fontWeight:"bold", backgroundColor: "#383E86", borderRadius:"24px", color:"white", borderColor:"white"}}>新規会員登録</Button>
                      }
                    </Stack>

                  </Stack>
                </Drawer>
              </Stack>
              
              <ProfilePopover user_name={user?.user_name ?? ""} profile_image_name={user?.profile_image_name ?? null} profile_image_uri={user?.profile_image_uri ?? null} user_type={user?.type} user_id={user?.user_id} anchorEL={anchorEL} setAnchorEL={setAnchorEL}/>
            </Stack>
            
          </Stack>
        </Stack>
      </Stack>
    </ThemeProvider>
  )
}

export default HeaderSP;